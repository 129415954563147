const initialState = {
  currentSchedule: {},
  buildLog: [],
  showFullscreenLog: false,
  fetchingPMBuildLog: false,
  shouldFetchPMBuildLog: true,
  serverPingStatus: [],
  hasAccess: false,
  buildLogRetryCount: 0,
  gdhLogs: { ntx_status: null, sli_status: null },
  showSplunkTimeoutModal: false,
  enableManualSplunkBlackout: false,
  gdhStartNtxLogs: { ntx_status: null },
  gdhStartSliLogs: { sli_status: null },
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case 'SET_PM_CURRENT_SCHEDULE': {
      return {
        ...state,
        currentSchedule: action.payload,
      };
    }
    case 'SET_PM_BUILD_LOG': {
      return {
        ...state,
        buildLog: action.payload,
      };
    }
    case 'SET_PM_SHOULD_FETCH_BUILD_LOG': {
      return {
        ...state,
        shouldFetchPMBuildLog: action.payload,
      };
    }
    case 'PM_SHOW_FULLSCREEN_LOG': {
      return {
        ...state,
        showFullscreenLog: action.payload,
      };
    }
    case 'SET_HAS_PM_ACCESS': {
      return {
        ...state,
        hasAccess: action.payload,
      };
    }
    case 'SET_PM_BUILD_LOG_FETCHING': {
      return {
        ...state,
        showFullscreenLog: action.payload,
      };
    }
    case 'SET_SERVER_PING_STATUS': {
      return {
        ...state,
        serverPingStatus: action.payload,
      };
    }
    case 'SET_PM_BUILD_LOG_RETRY_COUNT': {
      return {
        ...state,
        buildLogRetryCount: action.payload,
      };
    }
    case 'SET_PM_GDH_ACTIVITY_LOGS': {
      return {
        ...state,
        gdhLogs: action.payload,
      };
    }
    case 'SET_PM_GDH_ACTIVITY_NTX_LOGS': {
      return {
        ...state,
        gdhStartNtxLogs: action.payload,
      };
    }
    case 'SET_PM_GDH_ACTIVITY_SLI_LOGS': {
      return {
        ...state,
        gdhStartSliLogs: action.payload,
      };
    }
    case 'SET_SPLUNK_TIMEOUT_MODAL':
      return {
        ...state,
        showSplunkTimeoutModal: action.payload,
      };
    case 'SET_ENABLE_SPLUNK_MANUAL':
      return {
        ...state,
        enableManualSplunkBlackout: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
