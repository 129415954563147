const initialState = {
  pmReportData: [],
  filteredPMReportData: [],
  showPMReportingFilter: false,
  selectedCountryValue: [],
  selectedStoreCodeValue: [],
  selectedMTypeValue: [],
  selectedStatusValue: [],
  dateFilterValue: [],
  showPMReportDetailView: false,
  reportModalData: [],
  monthFilterValue: [],
  currentPMReportingId: '',
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case 'SET_PM_IS_FETCHING_REPORTING':
      return {
        ...state,
        isFetchingReport: action.payload,
      };
    case 'SET_PM_REPORTING_DATA':
      return {
        ...state,
        pmReportData: action.payload,
      };
    case 'SET_FILTERED_PM_REPORTING_DATA':
      return {
        ...state,
        filteredPMReportData: action.payload,
      };

    case 'SET_SHOW_PM_REPORTTING_FILTER_MODAL':
      return {
        ...state,
        showPMReportingFilter: action.payload.show,
      };

    case 'SET_FILTER_COUNTRY':
      return {
        ...state,
        selectedCountryValue: action.payload,
      };
    case 'SET_FILTER_STORECODE':
      return {
        ...state,
        selectedStoreCodeValue: action.payload,
      };

    case 'SET_FILTER_MTYPE':
      return {
        ...state,
        selectedMTypeValue: action.payload,
      };

    case 'SET_FILTER_MSTATUS':
      return {
        ...state,
        selectedStatusValue: action.payload,
      };
    case 'SET_FILTER_DATE':
      return {
        ...state,
        dateFilterValue: action.payload,
      };
    case 'SET_FILTER_MONTH':
      return {
        ...state,
        monthFilterValue: action.payload,
      };

    case 'SET_SHOW_PM_REPORT_DETAIL_MODAL':
      return {
        ...state,
        showPMReportDetailView: action.payload.show,
      };

    case 'SET_SHOW_PM_REPORT_DETAIL_DATA':
      return {
        ...state,
        reportModalData: action.payload.data,
      };
    case 'SET_CURRENT_PM_REPORTING_ID': {
      return {
        ...state,
        currentPMReportingId: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
