/*
 * IPM, platform to perform power maintenance in IKEA stores.
 * Copyright (C) 2023 IKEA
 * mailto:praveenkumar.s@ingka.com
 *
 * IPM is a web platform to plan, manage and perform power maintenance in IKEA stores
 *
 */

import { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import ProtectedRoute from './components/route/ProtectedRoute';
import LoadingWidget from './components/utils/LoadingWidget';
import {
  getTSSAuthData,
  setAuthToken,
  setIsPMAdmin,
  setIsPMUser,
  setSupplyIsPMAdmin,
  setSupplyIsPMUser,
} from './redux/authentication/actions';

import usePMAuth from './hooks/usePMAuth';
import useSupplyPMAuth from './hooks/useSupplyPMAuth';

import './GlobalStyles';
import { getEnvironment } from './utils/utility';

const PowerMaintenanceLandingPage = lazy(() =>
  import(/* webpackChunkName: "PowerMaintenanceLandingPage" */ './pages/landingPage'),
);

const PowerMaintenanceRetailHomePage = lazy(() =>
  import(/* webpackChunkName: "PowerMaintenanceRetailHomePage" */ './pages/powermaintenance/start'),
);
const SchedulePMPage = lazy(() => import(/* webpackChunkName: "SchedulePMPage" */ './pages/powermaintenance/schedule'));
const MyPMSchedules = lazy(() =>
  import(/* webpackChunkName: "MyPMSchedules" */ './pages/powermaintenance/list/PMScheduleList'),
);
const PMAssignmentList = lazy(() =>
  import(/* webpackChunkName: "PMAssignmentList" */ './pages/powermaintenance/list/PMAssignmentList'),
);
const PMAdminList = lazy(() =>
  import(/* webpackChunkName: "PMAdminList" */ './pages/powermaintenance/list/PMAdminList'),
);
const PMBuildPage = lazy(() => import(/* webpackChunkName: "PMBuildPage" */ './pages/powermaintenance/build'));
const PMBuildAdminViewPage = lazy(() =>
  import(/* webpackChunkName: "PMBuildAdminViewPage" */ './pages/powermaintenance/build/AdminView'),
);

const UserManagement = lazy(() =>
  import(/* webpackChunkName: "UserManagement" */ './pages/powermaintenance/userManagement'),
);

const SupportCenter = lazy(() =>
  import(/* webpackChunkName: "SupportCenter" */ './pages/powermaintenance/supportCenter'),
);

const PMReportingPage = lazy(() =>
  import(/* webpackChunkName: "PMReportingPage" */ './pages/powermaintenance/reportDashboard'),
);

const ScheduleManagerPage = lazy(() =>
  import(/* webpackChunkName: "ScheduleManagerPage" */ './pages/powermaintenance/scheduleManager'),
);

// Supply routes
const SupplyBusinessUnitPage = lazy(() =>
  import(/* webpackChunkName: "SupplyBusinessUnitPage" */ './pages/supply/businessunit'),
);

const SupplyPowerMaintenanceStartPage = lazy(() =>
  import(/* webpackChunkName: "SupplyPowerMaintenanceStartPage" */ './pages/supply/start'),
);
const SupplyPowerMaintenanceSchedulePage = lazy(() =>
  import(/* webpackChunkName: "SupplyPowerMaintenanceSchedulePage" */ './pages/supply/schedule'),
);

const SupplyMyPMSchedules = lazy(() =>
  import(/* webpackChunkName: "SupplyMyPMSchedules" */ './pages/supply/list/PMScheduleList'),
);

const SupplyPMAdminList = lazy(() =>
  import(/* webpackChunkName: "SupplyPMAdminList" */ './pages/supply/list/PMAdminList'),
);

const SupplyUserManagement = lazy(() =>
  import(/* webpackChunkName: "SupplyUserManagement" */ './pages/supply/userManagement'),
);

const SupplyPMAssignmentList = lazy(() =>
  import(/* webpackChunkName: "PMAssignmentList" */ './pages/supply/list/PMAssignmentList'),
);

const SupplyPMBuildPage = lazy(() => import(/* webpackChunkName: "SupplyPMBuildPage" */ './pages/supply/build/index'));

const SupplyPMBuildAdminViewPage = lazy(() =>
  import(/* webpackChunkName: "SupplyPMBuildAdminViewPage" */ './pages/supply/build/AdminView'),
);

const SupplySupportCenter = lazy(() =>
  import(/* webpackChunkName: "SupplySupportCenter" */ './pages/supply/supportCenter'),
);
const NotFoundPage = lazy(() => import(/* webpackChunkName: "NotFoundPage" */ './pages/errors/NotFoundPage'));

const App = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isLoading, user } = useAuth0();
  const [isPMUser, isPMAdmin] = usePMAuth();
  const supplyAuth = useSupplyPMAuth();
  const [token, setToken] = useState('');
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    dispatch(setIsPMUser(isPMUser));
  }, [isPMUser]);

  useEffect(() => {
    dispatch(setIsPMAdmin(isPMAdmin));
  }, [isPMAdmin]);

  useEffect(() => {
    const [isSupplyUser, isSupplyAdmin] = supplyAuth;
    dispatch(setSupplyIsPMUser(isSupplyUser));
    dispatch(setSupplyIsPMAdmin(isSupplyAdmin));
  }, [supplyAuth]);

  useEffect(() => {
    if (token && token !== '') {
      dispatch(setAuthToken(token));
      dispatch(getTSSAuthData(token, user.email));
    }
  }, [token, dispatch]);

  if (isLoading) {
    return <LoadingWidget />;
  }

  return (
    <Suspense fallback={<LoadingWidget />}>
      <Routes>
        <Route index path="/" element={<ProtectedRoute component={PowerMaintenanceLandingPage} />} />
        <Route index path="/schedule" element={<Navigate to={'/retail/schedule'} />} />
        <Route index path="/my-schedules" element={<Navigate to={'/retail/my-schedules'} />} />
        <Route index path="/my-assignments" element={<Navigate to={'/retail/my-assignments'} />} />
        <Route index path="/manage-pm" element={<Navigate to={'/retail/manage-pm'} />} />
        <Route index path="/pm/:fid/:operation" element={<Navigate to={'/retail/pm/:fid/:operation'} />} />
        <Route index path="/view/:fid/:operation" element={<Navigate to={'/retail/view/:fid/:operation'} />} />
        <Route index path="/user-management" element={<Navigate to={'/retail/user-management'} />} />
        <Route index path="/reporting" element={<Navigate to={'/retail/reporting'} />} />
        <Route index path="/schedule-manager" element={<Navigate to={'/retail/schedule-manager'} />} />

        {/* New routes for retail  */}
        <Route index path="/retail" element={<ProtectedRoute component={PowerMaintenanceRetailHomePage} />} />
        <Route index path="/retail/schedule" element={<ProtectedRoute component={SchedulePMPage} />} />
        <Route index path="/retail/my-schedules" element={<ProtectedRoute component={MyPMSchedules} />} />
        <Route index path="/retail/my-assignments" element={<ProtectedRoute component={PMAssignmentList} />} />
        <Route index path="/retail/manage-pm" element={<ProtectedRoute component={PMAdminList} />} />
        <Route index path="/retail/pm/:fid/:operation" element={<ProtectedRoute component={PMBuildPage} />} />
        <Route
          index
          path="/retail/view/:fid/:operation"
          element={<ProtectedRoute component={PMBuildAdminViewPage} />}
        />
        <Route index path="/retail/support-center" element={<ProtectedRoute component={SupportCenter} />} />
        <Route index path="/retail/user-management" element={<ProtectedRoute component={UserManagement} />} />
        <Route index path="/retail/reporting" element={<ProtectedRoute component={PMReportingPage} />} />
        <Route index path="/retail/schedule-manager" element={<ProtectedRoute component={ScheduleManagerPage} />} />

        {/* New routes for supply  */}

        <Route index path="/supply/bu" element={<ProtectedRoute component={SupplyBusinessUnitPage} />} />
        <Route index path="/supply" element={<ProtectedRoute component={SupplyPowerMaintenanceStartPage} />} />
        <Route
          index
          path="/supply/schedule"
          element={<ProtectedRoute component={SupplyPowerMaintenanceSchedulePage} />}
        />

        <Route index path="/supply/my-schedules" element={<ProtectedRoute component={SupplyMyPMSchedules} />} />

        <Route index path="/supply/manage-pm" element={<ProtectedRoute component={SupplyPMAdminList} />} />

        <Route index path="/supply/user-management" element={<ProtectedRoute component={SupplyUserManagement} />} />

        <Route index path="/supply/my-assignments" element={<ProtectedRoute component={SupplyPMAssignmentList} />} />
        <Route index path="/supply/pm/:fid/:operation" element={<ProtectedRoute component={SupplyPMBuildPage} />} />
        <Route
          index
          path="/supply/view/:fid/:operation"
          element={<ProtectedRoute component={SupplyPMBuildAdminViewPage} />}
        />
        <Route index path="/supply/support-center" element={<ProtectedRoute component={SupplySupportCenter} />} />

        <Route index path="*" element={<ProtectedRoute component={NotFoundPage} />} />
      </Routes>
    </Suspense>
  );
};

export default App;
