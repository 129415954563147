import {
  AUTHENTICATION_SUCCESS,
  SET_AUTH_TOKEN,
  SET_IS_PM_USER,
  SET_IS_PM_ADMIN,
  SET_SUPPLY_IS_PM_ADMIN,
  SET_SUPPLY_IS_PM_USER,
  SET_TSS_AUTH_DATA,
} from './types';

const initialState = {
  isLoggedIn: false,
  authToken: '',
  isPMUser: false,
  isPMAdmin: false,
  isSupplyUser: false,
  isSupplyAdmin: false,
  tssAuthData: {},
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    case SET_IS_PM_USER:
      return {
        ...state,
        isPMUser: action.payload,
      };
    case SET_IS_PM_ADMIN:
      return {
        ...state,
        isPMAdmin: action.payload,
      };
    case SET_SUPPLY_IS_PM_ADMIN:
      return {
        ...state,
        isSupplyAdmin: action.payload,
      };
    case SET_SUPPLY_IS_PM_USER:
      return {
        ...state,
        isSupplyUser: action.payload,
      };
    case SET_TSS_AUTH_DATA:
      return {
        ...state,
        tssAuthData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
