import {
  SET_SUPPLY_USER_MANAGEMENT_FETCHING_FLAG,
  SET_SUPPLY_ADMIN_USERS,
  SET_SUPPLY_AGENT_USERS,
  SET_SUPPLY_SHOW_ADD_USER_MODAL,
  SET_SUPPLY_ADD_USER_MODAL_TYPE,
  SET_SUPPLY_ALLOWED_JOB_TITLE,
  SET_SUPPLY_SHOW_ADD_JOB_TITLE_MODAL,
} from './types';

const initialState = {
  adminUsers: [],
  agentUsers: [],
  showAddUserModal: false,
  addUserModalType: '',
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SUPPLY_SHOW_ADD_USER_MODAL:
      return {
        ...state,
        showAddUserModal: action.payload,
      };
    case SET_SUPPLY_ADD_USER_MODAL_TYPE:
      return {
        ...state,
        addUserModalType: action.payload,
      };
    case SET_SUPPLY_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.payload,
      };
    case SET_SUPPLY_AGENT_USERS:
      return {
        ...state,
        agentUsers: action.payload,
      };

    case SET_SUPPLY_ALLOWED_JOB_TITLE:
      return {
        ...state,
        allowedJobTitles: action.payload,
      };

    case SET_SUPPLY_SHOW_ADD_JOB_TITLE_MODAL:
      return {
        ...state,
        showAddUserRoleModal: action.payload,
      };

    case SET_SUPPLY_USER_MANAGEMENT_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case 'addAdminUser': {
          return {
            ...state,
            addingAdmin: value,
          };
        }
        case 'addAgentUser': {
          return {
            ...state,
            addingAgent: value,
          };
        }
        case 'getAdminUsers': {
          return {
            ...state,
            fetchingAdminUsers: value,
          };
        }
        case 'getAgentUsers': {
          return {
            ...state,
            fetchingAgentUsers: value,
          };
        }
        case 'getAllowedJobTitles': {
          return {
            ...state,
            fetchingAllowedJobTitles: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return state;
  }
};

export default reducer;
