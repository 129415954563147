import { combineReducers } from 'redux';

import authReducer from './authentication/reducer';
import pmReducer from './powermaintenance/reducer';
import pmScriptsReducer from './powermaintenance/triggerReducer';
import userManagementReducer from './userManagement/reducer';
import utilReducer from './util/reducer';
import pmReportingDashboard from './pmReportingDashboard/reducer';
import manageScheduleDates from './manageScheduleDates/reducer';
import supplyPMReducer from './supplyPM/reducers';
import supplyUserManagementReducer from './supplyUserManagement/reducer';
import supplyPMScriptsReducer from './supplyPM/triggerReducer';
import pmAdminListReducer from './powermaintenance/adminListReducer';

const rootReducer = combineReducers({
  authReducer,
  pmReducer,
  pmScriptsReducer,
  userManagementReducer,
  utilReducer,
  pmReportingDashboard,
  manageScheduleDates,
  supplyPMReducer,
  supplyUserManagementReducer,
  supplyPMScriptsReducer,
  pmAdminListReducer,
});

export default rootReducer;
