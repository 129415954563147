export const SET_SUPPLY_PM_SCHEDULE_FORM_DATA = 'SET_SUPPLY_PM_SCHEDULE_FORM_DATA';
export const CLEAR_SUPPLY_PM_SCHEDULE_FORM_DATA = 'CLEAR_SUPPLY_PM_SCHEDULE_FORM_DATA';
export const SET_SUPPLY_PM_IS_EDIT = 'SET_SUPPLY_PM_IS_EDIT';
export const SET_SUPPLY_SHOW_PM_SCHEDULE_MODAL = 'SET_SUPPLY_SHOW_PM_SCHEDULE_MODAL';
export const SET_SUPPLY_SHOW_PM_ASSIGN_MODAL = 'SET_SUPPLY_SHOW_PM_ASSIGN_MODAL';
export const SET_SUPPLY_PM_SELECTED_SCHEDULE = 'SET_SUPPLY_PM_SELECTED_SCHEDULE';
export const SET_SUPPLY_PM_LIST = 'SET_SUPPLY_PM_LIST';
export const SET_SUPPLY_PM_SERVER_LIST = 'SET_SUPPLY_PM_SERVER_LIST';
export const SET_SUPPLY_PM_AVAILABLE_SERVER_LIST = 'SET_SUPPLY_PM_AVAILABLE_SERVER_LIST';
export const SET_SUPPLY_SHOW_PM_SUMMARY_MODAL = 'SET_SUPPLY_SHOW_PM_SUMMARY_MODAL';
export const SET_SUPPLY_SHOW_PM_HISTORY_MODAL = 'SET_SUPPLY_SHOW_PM_HISTORY_MODAL';
export const SET_SUPPLY_SHOW_PM_DELETE_MODAL = 'SET_SUPPLY_SHOW_PM_DELETE_MODAL';
export const SET_SUPPLY_SHOW_PM_SERVER_DETAILS_MODAL = 'SET_SUPPLY_SHOW_PM_SERVER_DETAILS_MODAL';
export const SET_SUPPLY_PM_LOCATION_DATA = 'SET_SUPPLY_PM_LOCATION_DATA';
export const SET_SUPPLY_PM_STOP_AGENT = 'SET_SUPPLY_PM_STOP_AGENT';
export const SET_SUPPLY_PM_START_AGENT = 'SET_SUPPLY_PM_START_AGENT';
export const SET_SUPPLY_PM_CHANGE_NUMBER = 'SET_SUPPLY_PM_CHANGE_NUMBER';
export const SET_SUPPLY_PM_FETCHING_FLAG = 'SET_SUPPLY_PM_FETCHING_FLAG';
export const SET_SUPPLY_PM_ACTIVE_TAB_VIEW_NAME = 'SET_SUPPLY_PM_ACTIVE_TAB_VIEW_NAME';
export const SET_SUPPLY_PM_CURRENT_SCHEDULE = 'SET_SUPPLY_PM_CURRENT_SCHEDULE';
export const SET_SUPPLY_PM_BUILD_LOG = 'SET_SUPPLY_PM_BUILD_LOG';
export const SET_SUPPLY_PM_SHOULD_FETCH_BUILD_LOG = 'SET_SUPPLY_PM_SHOULD_FETCH_BUILD_LOG';
export const SET_SUPPLY_PM_BUILD_LOG_FETCHING = 'SET_SUPPLY_PM_BUILD_LOG_FETCHING';
export const SUPPLY_PM_SHOW_FULLSCREEN_LOG = 'SUPPLY_PM_SHOW_FULLSCREEN_LOG';
export const SET_SUPPLY_HAS_PM_ACCESS = 'SET_SUPPLY_HAS_PM_ACCESS';
export const SET_SUPPLY_SERVER_PING_STATUS = 'SET_SUPPLY_SERVER_PING_STATUS';
export const SET_SUPPLY_SHOW_HANDOVER_MODAL = 'SET_SUPPLY_SHOW_HANDOVER_MODAL';