import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <>
      <Auth0Provider
        domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        responseType={'token id_token'}
        audience={import.meta.env.VITE_APP_AUTH0_AUDIENCE}
        scope={'read write update delete'}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    </>
  );
};

export default Auth0ProviderWithHistory;
