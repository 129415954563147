export const SET_PM_USER_MANAGEMENT_FETCHING_FLAG = 'SET_PM_USER_MANAGEMENT_FETCHING_FLAG';
export const SET_PM_ADMIN_USERS = 'SET_PM_ADMIN_USERS';
export const SET_PM_AGENT_USERS = 'SET_PM_AGENT_USERS';
export const SET_SHOW_ADD_USER_MODAL = 'SET_SHOW_ADD_USER_MODAL';
export const SET_ADD_USER_MODAL_TYPE = 'SET_ADD_USER_MODAL_TYPE';
export const SET_PM_ALLOWED_JOB_TITLE = 'SET_PM_ALLOWED_JOB_TITLE';
export const SET_SHOW_ADD_JOB_TITLE_MODAL = 'SET_SHOW_ADD_JOB_TITLE_MODAL';
export const SET_PM_TSS_ADMIN_USERS = 'SET_PM_TSS_ADMIN_USERS';
export const SET_PM_TSS_AGENT_USERS = 'SET_PM_TSS_AGENT_USERS';
export const SET_SHOW_ADD_TSS_USER_MODAL = 'SET_SHOW_ADD_TSS_USER_MODAL';
export const SET_ADD_TSS_USER_MODAL_TYPE = 'SET_ADD_TSS_USER_MODAL_TYPE';
export const SET_HANDOVER_USERS_LIST = 'SET_HANDOVER_USERS_LIST';
