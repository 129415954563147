const initialState = {
  pmList: {
    active: [],
    completed: [],
    cancelled: [],
  },
  generatorTestList: {
    active: [],
    completed: [],
    cancelled: [],
  },
  isFetchingGeneratorTestActiveList: false,
  isFetchingGeneratorTestCompletedList: false,
  isFetchingGeneratorTestCancelledList: false,
  isFetchingPMActiveList: false,
  isFetchingPMCompletedList: false,
  isFetchingPMCancelledList: false,
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case 'SET_ADMIN_LIST_DATA':
      const { list, maintenanceType, status } = action.payload;
      const mType = maintenanceType ==='Power Maintenance' ? 'pmList' : 'generatorTestList';
      return {
        ...state,
        [mType]: {
          ...state[mType],
          [status]: list,
        },
      };

    case 'SET_FETCHING_ADMIN_LIST_DATA': {
      const { value, maintenanceType, status } = action.payload;
      if (maintenanceType === 'Power Maintenance') {
        switch (status) {
          case 'active':
            return {
              ...state,
              isFetchingPMActiveList: value,
            };
          case 'completed':
            return {
              ...state,
              isFetchingPMCompletedList: value,
            };
          case 'cancelled':
            return {
              ...state,
              isFetchingPMCancelledList: value,
            };
          default:
            return state;
        }
      } else {
        switch (status) {
          case 'active':
            return {
              ...state,
              isFetchingGeneratorTestActiveList: value,
            };
          case 'completed':
            return {
              ...state,
              isFetchingGeneratorTestCompletedList: value,
            };
          case 'cancelled':
            return {
              ...state,
              isFetchingGeneratorTestCancelledList: value,
            };
          default:
            return state;
        }
      }
    }
    default:
      return state;
  }
};

export default reducer;
