import {
  CLEAR_SUPPLY_PM_SCHEDULE_FORM_DATA,
  SET_SUPPLY_PM_ACTIVE_TAB_VIEW_NAME,
  SET_SUPPLY_PM_AVAILABLE_SERVER_LIST,
  SET_SUPPLY_PM_CHANGE_NUMBER,
  SET_SUPPLY_PM_FETCHING_FLAG,
  SET_SUPPLY_PM_IS_EDIT,
  SET_SUPPLY_PM_LIST,
  SET_SUPPLY_PM_LOCATION_DATA,
  SET_SUPPLY_PM_SCHEDULE_FORM_DATA,
  SET_SUPPLY_PM_SELECTED_SCHEDULE,
  SET_SUPPLY_PM_SERVER_LIST,
  SET_SUPPLY_PM_START_AGENT,
  SET_SUPPLY_PM_STOP_AGENT,
  SET_SUPPLY_SHOW_PM_ASSIGN_MODAL,
  SET_SUPPLY_SHOW_PM_DELETE_MODAL,
  SET_SUPPLY_SHOW_PM_HISTORY_MODAL,
  SET_SUPPLY_SHOW_PM_SCHEDULE_MODAL,
  SET_SUPPLY_SHOW_PM_SERVER_DETAILS_MODAL,
  SET_SUPPLY_SHOW_PM_SUMMARY_MODAL,
  SET_SUPPLY_SHOW_HANDOVER_MODAL,
} from './types';

const initialState = {
  storeList: [],
  showScheduleModal: false,
  showPMAssignModal: false,
  isEdit: false,
  selectedSchedule: {},
  pmList: [],
  serverList: [],
  availableServerList: [],
  pmScheduleFormData: {},
  allowedJobTitles: [],

  stopAgent: '',
  startAgent: '',
  changeNumber: '',
  showPMDelete: false,
  pmReportData: [],
  activeTabView: '',
  locationList: [],
  showHandoverModal: false,
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SUPPLY_PM_SCHEDULE_FORM_DATA:
      return {
        ...state,
        pmScheduleFormData: {
          ...state.pmScheduleFormData,
          ...action.payload,
          // [action.payload.page]: action.payload.formData
        },
      };
    case SET_SUPPLY_PM_LOCATION_DATA:
      return {
        ...state,
        locationList: action.payload,
      };
    case CLEAR_SUPPLY_PM_SCHEDULE_FORM_DATA:
      return {
        ...state,
        pmScheduleFormData: {},
      };
    case SET_SUPPLY_PM_IS_EDIT:
      return {
        ...state,
        isEdit: action.payload,
      };
    case SET_SUPPLY_SHOW_PM_SCHEDULE_MODAL:
      return {
        ...state,
        showScheduleModal: action.payload.show,
        isEdit: action.payload.isEdit,
      };
    case SET_SUPPLY_SHOW_PM_ASSIGN_MODAL:
      return {
        ...state,
        showPMAssignModal: action.payload,
      };
    case SET_SUPPLY_SHOW_HANDOVER_MODAL:
      return {
        ...state,
        showHandoverModal: action.payload,
      };
    case SET_SUPPLY_PM_SELECTED_SCHEDULE: {
      return {
        ...state,
        selectedSchedule: action.payload,
      };
    }
    case SET_SUPPLY_PM_LIST:
      return {
        ...state,
        pmList: action.payload,
      };
    case SET_SUPPLY_PM_SERVER_LIST:
      return {
        ...state,
        serverList: action.payload,
      };
    case SET_SUPPLY_PM_AVAILABLE_SERVER_LIST:
      return {
        ...state,
        availableServerList: action.payload,
      };
    case SET_SUPPLY_SHOW_PM_SUMMARY_MODAL:
      return {
        ...state,
        showPMSummary: action.payload.show,
      };
    case SET_SUPPLY_SHOW_PM_HISTORY_MODAL:
      return {
        ...state,
        showPMHistory: action.payload.show,
      };
    case SET_SUPPLY_SHOW_PM_DELETE_MODAL:
      return {
        ...state,
        showPMDelete: action.payload.show,
      };
    case SET_SUPPLY_SHOW_PM_SERVER_DETAILS_MODAL:
      return {
        ...state,
        showPMServerDetails: action.payload.show,
      };
    case SET_SUPPLY_PM_STOP_AGENT:
      return {
        ...state,
        stopAgent: action.payload,
      };
    case SET_SUPPLY_PM_START_AGENT:
      return {
        ...state,
        startAgent: action.payload,
      };
    case SET_SUPPLY_PM_CHANGE_NUMBER:
      return {
        ...state,
        changeNumber: action.payload,
      };
    case SET_SUPPLY_PM_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case 'getLocationList': {
          return {
            ...state,
            fetchingLocationList: value,
          };
        }
        case 'schedulePM': {
          return {
            ...state,
            savingPM: value,
          };
        }
        case 'updatePM': {
          return {
            ...state,
            savingPM: value,
          };
        }
        case 'deletePM': {
          return {
            ...state,
            deletingPM: value,
          };
        }
        case 'getPMList': {
          return {
            ...state,
            fetchingPMList: value,
          };
        }
        case 'getAllPMList': {
          return {
            ...state,
            fetchingPMList: value,
          };
        }
        case 'getAssignedPMList': {
          return {
            ...state,
            fetchingPMList: value,
          };
        }
        case 'getServerList': {
          return {
            ...state,
            fetchingServerList: value,
          };
        }
        case 'assignAgent': {
          return {
            ...state,
            assigningAgent: value,
          };
        }
        case 'saveAgent': {
          return {
            ...state,
            savingAgent: value,
          };
        }
        case 'getCurrentPM': {
          return {
            ...state,
            fetchingCurrentPM: value,
          };
        }
        case 'triggerBuild': {
          return {
            ...state,
            triggeringBuild: value,
          };
        }
        case 'getPMBuildStatus': {
          return {
            ...state,
            fetchingBuildStatus: value,
          };
        }
        case 'getServerPingStatus': {
          return {
            ...state,
            fetchingServerPingStatus: value,
          };
        }
        case 'generateOTPForPM': {
          return {
            ...state,
            sendingOTP: value,
          };
        }
        case 'validateOTPForPM': {
          return {
            ...state,
            validatingOTP: value,
          };
        }
        case 'updatePMScheduleServerList': {
          return {
            ...state,
            isUpdatingPMServerList: value,
          };
        }
        case 'handoverPM': {
          return {
            ...state,
            isUpdatingHandoverAgent: value,
          };
        }
        case 'startPMWithoutTrigger': {
          return {
            ...state,
            startingPMOperation: value,
          };
        }
        default:
          return { ...state };
      }
    }
    case SET_SUPPLY_PM_ACTIVE_TAB_VIEW_NAME:
      return {
        ...state,
        activeTabView: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
