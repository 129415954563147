import {
  SET_SUPPLY_HAS_PM_ACCESS,
  SET_SUPPLY_PM_BUILD_LOG,
  SET_SUPPLY_PM_BUILD_LOG_FETCHING,
  SET_SUPPLY_PM_CURRENT_SCHEDULE,
  SET_SUPPLY_PM_SHOULD_FETCH_BUILD_LOG,
  SET_SUPPLY_SERVER_PING_STATUS,
  SUPPLY_PM_SHOW_FULLSCREEN_LOG,
} from './types';

const initialState = {
  currentSchedule: {},
  buildLog: [],
  showFullscreenLog: false,
  fetchingPMBuildLog: false,
  shouldFetchPMBuildLog: true,
  serverPingStatus: [],
  hasAccess: false,
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SUPPLY_PM_CURRENT_SCHEDULE: {
      return {
        ...state,
        currentSchedule: action.payload,
      };
    }
    case SET_SUPPLY_PM_BUILD_LOG: {
      return {
        ...state,
        buildLog: action.payload,
      };
    }
    case SET_SUPPLY_PM_SHOULD_FETCH_BUILD_LOG: {
      return {
        ...state,
        shouldFetchPMBuildLog: action.payload,
      };
    }
    case SUPPLY_PM_SHOW_FULLSCREEN_LOG: {
      return {
        ...state,
        showFullscreenLog: action.payload,
      };
    }
    case SET_SUPPLY_HAS_PM_ACCESS: {
      return {
        ...state,
        hasAccess: action.payload,
      };
    }
    case SET_SUPPLY_PM_BUILD_LOG_FETCHING: {
      return {
        ...state,
        showFullscreenLog: action.payload,
      };
    }
    case SET_SUPPLY_SERVER_PING_STATUS: {
      return {
        ...state,
        serverPingStatus: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
