import {
  SET_PM_USER_MANAGEMENT_FETCHING_FLAG,
  SET_PM_ADMIN_USERS,
  SET_PM_AGENT_USERS,
  SET_SHOW_ADD_USER_MODAL,
  SET_ADD_USER_MODAL_TYPE,
  SET_PM_ALLOWED_JOB_TITLE,
  SET_SHOW_ADD_JOB_TITLE_MODAL,
  SET_PM_TSS_ADMIN_USERS,
  SET_PM_TSS_AGENT_USERS,
  SET_SHOW_ADD_TSS_USER_MODAL,
  SET_ADD_TSS_USER_MODAL_TYPE,
  SET_HANDOVER_USERS_LIST,
} from './types';
const initialState = {
  adminUsers: [],
  agentUsers: [],
  showAddUserModal: false,
  addUserModalType: '',
  allowedJobTitles: [],
  showAddUserRoleModal: false,
  tssAdminUsers: [],
  tssAgentUsers: [],
  showAddTSSUserModal: false,
  addTSSUserModalType: '',
  handoverAgents: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SHOW_ADD_USER_MODAL:
      return {
        ...state,
        showAddUserModal: action.payload,
      };
    case SET_ADD_USER_MODAL_TYPE:
      return {
        ...state,
        addUserModalType: action.payload,
      };
    case SET_PM_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.payload,
      };
    case SET_PM_AGENT_USERS:
      return {
        ...state,
        agentUsers: action.payload,
      };
    case SET_PM_ALLOWED_JOB_TITLE:
      return {
        ...state,
        allowedJobTitles: action.payload,
      };
    case SET_SHOW_ADD_JOB_TITLE_MODAL:
      return {
        ...state,
        showAddUserRoleModal: action.payload,
      };
    case SET_PM_TSS_ADMIN_USERS:
      return {
        ...state,
        tssAdminUsers: action.payload,
      };
    case SET_PM_TSS_AGENT_USERS:
      return {
        ...state,
        tssAgentUsers: action.payload,
      };
    case SET_SHOW_ADD_TSS_USER_MODAL: {
      return {
        ...state,
        showAddTSSUserModal: action.payload,
      };
    }
    case SET_ADD_TSS_USER_MODAL_TYPE: {
      return {
        ...state,
        addTSSUserModalType: action.payload,
      };
    }
    case SET_HANDOVER_USERS_LIST: {
      return {
        ...state,
        handoverAgents: action.payload,
      };
    }
    case SET_PM_USER_MANAGEMENT_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case 'addAdminUser': {
          return {
            ...state,
            addingAdmin: value,
          };
        }
        case 'addAgentUser': {
          return {
            ...state,
            addingAgent: value,
          };
        }
        case 'getAdminUsers': {
          return {
            ...state,
            fetchingAdminUsers: value,
          };
        }
        case 'getAgentUsers': {
          return {
            ...state,
            fetchingAgentUsers: value,
          };
        }
        case 'getAllowedJobTitles': {
          return {
            ...state,
            fetchingAllowedJobTitles: value,
          };
        }
        case 'getTSSAdminUsers': {
          return {
            ...state,
            fetchingTSSAdminUsers: value,
          };
        }
        case 'getTSSAgentUsers': {
          return {
            ...state,
            fetchingTSSAgentUsers: value,
          };
        }
        case 'addTSSAgentUser': {
          return {
            ...state,
            addingTSSAgentUser: value,
          };
        }
        case 'addTSSAdminUser': {
          return {
            ...state,
            addingTSSAdminUser: value,
          };
        }
        case 'getHandoverAgents': {
          return {
            ...state,
            fetchingHandoverAgents: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return state;
  }
};

export default reducer;
