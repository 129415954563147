import moment from 'moment-timezone';

export const getEnvironment = () => {
  try {
    const env = import.meta.env.VITE_APP_ENVIRONMENT;
    return env ? env : 'DEV';
  } catch (error) {
    return 'DEV';
  }
};

export const getRetailBaseURL = () => {
  try {
    const env = getEnvironment();
    switch (env) {
      case 'DEV':
        return 'https://devipm-backend.ingka.com/api/v1/retail';
      case 'STAGE':
        return 'https://stageipm-backend.ingkadt.com/api/v1/retail';
      case 'PROD':
        return 'https://ipm-backend.ingka.com/api/v1/retail';
      default:
        return 'https://devipm-backend.ingka.com/api/v1/retail';
    }
  } catch (error) {}
};

export const getPMBackendUrl = () => {
  try {
    const env = getEnvironment();
    switch (env) {
      case 'DEV':
        return 'https://dev-pm-backend-flexible-dot-ikea-itsd-ml.appspot.com';
      case 'STAGE':
        return 'https://stage-pm-backend-flexible-dot-ikea-itsd-ml.appspot.com';
      case 'PROD':
        return 'https://pm-backend-flexible-dot-ikea-itsd-ml.appspot.com';
      default:
        return 'https://dev-pm-backend-flexible-dot-ikea-itsd-ml.appspot.com';
    }
  } catch (error) {}
};

export const getNowITURL = () => {
  try {
    const env = getEnvironment();
    switch (env) {
      case 'DEV':
        return 'https://ingkatest.service-now.com';
      case 'STAGE':
        return 'https://ingkatest.service-now.com';
      case 'PROD':
        return 'https://ingkaprod.service-now.com';
      default:
        return 'https://ingkatest.service-now.com';
    }
  } catch (error) {}
};

export const formatNumber = (number) => {
  try {
    let formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
  } catch (error) {
    return number;
  }
};

export const groupBy = (list, keyGetter) => {
  const map = {};
  list.forEach((item, idx) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const groupByV2 = (list, keyGetter) => {
  const map = {};
  list.forEach((item, idx) => {
    const key = keyGetter(item);
    if (key) {
      const collection = map[key];
      if (!collection) {
        map[key] = [item];
      } else {
        collection.push(item);
      }
    }
  });
  return map;
};

export const getColours = (status) => {
  switch (status?.toLowerCase()) {
    case 'success':
      return '#0a8a00';
    case 'successful':
      return '#0a8a00';
    case 'job completed':
      return '#0a8a00';
    case 'succeeded':
      return '#0a8a00';
    case 'failure':
      return '#e00751';
    case 'failed':
      return '#e00751';
    case 'job suspended':
      return '#e00751';
    case 'job suspended by user':
      return '#e00751';
    case 'suspended':
      return '#e00751';
    case 'in-progress':
      return '#ffa524';
    case 'inprogress':
      return '#ffa524';
    case 'completed':
      return '#0a8a00';
    case 'in progress':
      return '#ffa524';
    case 'running':
      return '#ffa524';
    case 'started':
      return '#ffa524';
    case 'created':
      return '#0058a3';
    case 'enabled':
      return '#0a8a00';
    case 'disabled':
      return '#e00751';
    case 'canceled':
      return '#f28aae';
    case 'others':
      return '#000000';
    default:
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
};

export const getStatus = (status) => {
  switch (status?.toLowerCase()) {
    case 'success':
      return 'success';
    case 'successful':
      return 'success';
    case 'job completed':
      return 'success';
    case 'failure':
      return 'failed';
    case 'failed':
      return 'failed';
    case 'job suspended':
      return 'failed';
    case 'job suspended by user':
      return 'failed';
    case 'suspended':
      return 'failed';
    case 'in-progress':
      return 'pending';
    case 'completed':
      return 'success';
    case 'in progress':
      return 'pending';
    case 'running':
      return 'pending';
    case 'started':
      return 'pending';
    case 'created':
      return 'pending';
    case 'others':
      return 'pending';
    default:
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
};

export const equalsIgnoringCase = (text, other) => {
  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
};

export const formatDate = (data, format = 'MMM Do YYYY, HH:mm:ss') => {
  try {
    if (data && data !== '') {
      return moment(data).tz('Europe/Berlin').format(format);
    }
    // else return data;
    else return '-';
  } catch (error) {
    console.error(`Error fomatting date :: ${error}`);
    return data;
  }
};

export const formatDateWithoutZone = (date, format = 'MMM Do YYYY, HH:mm:ss') => {
  try {
    if (date && date !== '') {
      return moment(date).format(format);
    }
    // else return date;
    else return '-';
  } catch (error) {
    console.error(`Error fomatting date :: ${error}`);
    return date;
  }
};

export const getDays = (type) => {
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff = now - start;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

export const calculatePercentage = (operand, total) => {
  try {
    return ((operand * 100) / total).toFixed(1) ?? 0;
  } catch (error) {
    console.log(`Error calculating percentage`);
    return 10;
  }
};

export const getFilterDate = (filterPeriod) => {
  var date = new Date();

  date.setDate(date.getDate() - filterPeriod);
  date.setHours(0, 0, 0, 0);

  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const downloadTxtFile = (
  content = '',
  fileName = `${'Automation_Ingka'}_${Date.parse(new Date().toISOString())}`,
) => {
  try {
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  } catch (error) {
    console.error(`Error in downloadTxtFile :: ${error}`);
  }
};

export const uuidv4 = () => {
  try {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
  } catch (error) {
    console.error(`Error in genereating uuidv4 :: ${error}`);
  }
};

export const sleep = async (ms = 3000) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve('resolved');
    }, ms),
  );
};

export const dispatchSychcronous = (action, scheduleId, payload, dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(action(scheduleId, payload));
    resolve();
  });

export const addDate = (startDate, amount = 1, type = 'days') => {
  try {
    const start = moment(startDate);
    var end = start.add(amount, type);
    return end;
  } catch (error) {
    console.error(`Error in addDate :: ${error}`);
    return startDate;
  }
};

export const dateDiff = (startTime, endTime) => {
  try {
    const start = moment(startTime);
    const end = moment(endTime);
    var duration = moment.duration(end.diff(start));
    var seconds = duration.asSeconds();
    return seconds;
  } catch (error) {
    console.error(`Error in dateDiff :: ${error}`);
    return '0s';
  }
};

export const copyToClipboard = (text) => {
  try {
    navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const convertTime = (dateTime = '', fromTimezone = '', toTimezone = 'Europe/Berlin') => {
  try {
    if (dateTime instanceof Date) {
      dateTime = moment(dateTime).format('YYYY-MM-DDTHH:mm');
    }
    var localTime = moment.tz(dateTime, fromTimezone);
    var CETTime = localTime.clone().tz('Europe/Berlin');
    const formattedCETDate = CETTime.format('YYYY-MM-DDTHH:mm');
    return formattedCETDate;
  } catch (error) {
    console.error(`Error while formatting Date/Time`);
    return dateTime;
  }
};

export const convertToTimeZone = (dateTime, toTimezone) => {
  try {
    const localTime = moment.tz(dateTime, toTimezone);
    const formattedDate = localTime.format('YYYY-MM-DDTHH:mm');
    return formattedDate;
  } catch (error) {
    console.error(`Error while formatting Date/Time`);
    return dateTime;
  }
};
export const getSupplyBaseUrl = () => {
  try {
    const env = getEnvironment();
    switch (env) {
      case 'DEV':
        return 'https://devipm-backend.ingka.com';
      case 'STAGE':
        return 'https://stageipm-backend.ingkadt.com';
      case 'PROD':
        return 'https://ipm-backend.ingka.com';
      default:
        return 'https://devipm-backend.ingka.com';
    }
  } catch (error) {}
};

const serviceTeamsMapping = {
  goem: 'CAP-RM-IPOS-SL1',
  gwls: 'GWLS - Weblogic Support',
  gint_integration_services: ' GINT - Global Integration support Team',
  gint: 'GINT - Global Integration support Team',
  pgdb: 'DBPS - Database Platform Services',
  gdba: 'DBMS - Database Management Services',
  glinux: 'GLINUXDST - Dist and Retails Linux servers',
  nsb: 'GDH - Group Distributed Hosting',
};

export const getTeamNameByService = (service) => {
  return serviceTeamsMapping[service] || '';
};

export const getCETLabel = (date) => {
  const offset = moment(date).tz('Europe/Berlin').utcOffset();
  return offset > 60 ? 'CEST' : 'CET';
};

export const isTestStore = (countryCode, storeCode) =>{
  const countries= ['SE','CN'];
  const stores = ['995','888'];
  return countries.includes(countryCode) && stores.includes(storeCode);
}
