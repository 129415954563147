import {
  SET_GLOBAL_TOAST_TEXT,
  SET_GLOBAL_TOAST_VISIBLE,
  SET_NOTIFICATION_LIST,
  SET_SHOW_NOTIFICATION,
  SET_SHOW_SIDEBAR,
} from './types';

const initialState = {
  globalToastVisible: false,
  globalToastText: '',
  showSideBar: false,
  showNotification: false,
  notificationList: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;
  switch (type) {
    case SET_GLOBAL_TOAST_VISIBLE: {
      return {
        ...state,
        globalToastVisible: action.payload,
      };
    }
    case SET_SHOW_SIDEBAR: {
      return {
        ...state,
        showSideBar: action.payload,
      };
    }
    case SET_GLOBAL_TOAST_TEXT: {
      return {
        ...state,
        globalToastText: action.payload,
      };
    }
    case SET_SHOW_NOTIFICATION: {
      return {
        ...state,
        showNotification: action.payload,
      };
    }
    case SET_NOTIFICATION_LIST: {
      return {
        ...state,
        notificationList: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default reducer;
