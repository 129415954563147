import axios from 'axios';
import { SET_SUPPLY_AGENT_USERS } from '../supplyUserManagement/types';
import {
  AUTHENTICATION_SUCCESS,
  SET_AUTH_TOKEN,
  SET_IS_PM_USER,
  SET_IS_PM_ADMIN,
  SET_SUPPLY_IS_PM_USER,
  SET_SUPPLY_IS_PM_ADMIN,
  SET_TSS_AUTH_DATA,
} from './types';
import { getRetailBaseURL } from '../../utils/utility';

export const authenticate = () => (dispatch) => {
  dispatch({ type: AUTHENTICATION_SUCCESS });
};

export const setAuthToken = (token) => (dispatch) => {
  dispatch({ type: SET_AUTH_TOKEN, payload: token });
};

export const setIsPMUser = (value) => (dispatch) => {
  dispatch({ type: SET_IS_PM_USER, payload: value });
};

export const setIsPMAdmin = (value) => (dispatch) => {
  dispatch({ type: SET_IS_PM_ADMIN, payload: value });
};

export const setSupplyIsPMUser = (value) => (dispatch) => {
  dispatch({ type: SET_SUPPLY_IS_PM_USER, payload: value });
};

export const setSupplyIsPMAdmin = (value) => (dispatch) => {
  dispatch({ type: SET_SUPPLY_IS_PM_ADMIN, payload: value });
};


export const getTSSAuthData =
  (authToken, userEmail = '') =>
  async (dispatch) => {
    const url = `${getRetailBaseURL()}/rbac/tss/check/${userEmail}`
    try {
      const res = await axios.get(url,{
        headers:{
          Authorization: `Bearer ${authToken}`,
        }
      })
      const {status, ...rest} = res.data;
      dispatch({
        type: SET_TSS_AUTH_DATA,
        payload: rest,
      });
    } catch (error) {
      console.error("Error while fetching tss auth data");
    }
  };

