const initialState = {
  timeBlockData: [],
  isFetchingTimeBlockData: false,
  showAddModal: false,
  showTimeBlockDelete: false,

  selectedTimeBlock: {},
  timeBlockId: '',
  defaultDateTime: {},
};
const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case 'SET_FETCHING_TIME_BLOCK_DATA':
      return {
        ...state,
        isFetchingTimeBlockData: action.payload,
      };
    case 'SET_TIME_BLOCK_DATA':
      return {
        ...state,
        timeBlockData: action.payload,
      };

    case 'SET_SHOW_ADD_TIME_BLOCK_MODAL':
      return {
        ...state,
        showAddModal: action.payload.show,
      };

    case 'SET_SHOW_DELETE_TIME_BLOCK_MODAL':
      return {
        ...state,
        showTimeBlockDelete: action.payload.show,
      };

    case 'SET_SELECTED_TIME_BLOCK':
      return {
        ...state,
        selectedTimeBlock: action.payload,
      };
    case 'SET_DELETE_TIME_BLOCK_ID':
      return {
        ...state,
        timeBlockId: action.payload,
      };

    case 'SET_DATETIME_ADD_TIME_BLOCK_MODAL':
      return {
        ...state,
        defaultDateTime: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
