import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useSupplyPMAuth = () => {
  const [isPMUser, setIsPMUser] = useState(false);
  const [isPMAdmin, setIsPMAdmin] = useState(false);

  const { user } = useAuth0();

  useEffect(() => {
    if (user && user['https://accounts.ikea.com/groups']) {
      if (user['https://accounts.ikea.com/groups']?.includes('IAM_AAD_Aiops-POWERMNT-supply')) {
        setIsPMUser(true);
      } else {
        setIsPMUser(false);
      }
    } else {
      setIsPMUser(false);
    }
    if (user && user['https://accounts.ikea.com/groups']) {
      if (user['https://accounts.ikea.com/groups']?.includes('IAM_AAD_Aiops-POWERMNT_admin_supply')) {
        setIsPMAdmin(true);
      } else {
        setIsPMAdmin(false);
      }
    } else {
      setIsPMAdmin(false);
    }
  }, [user]);

  return [isPMUser, isPMAdmin];
};

export default useSupplyPMAuth;
