import { CircularProgress } from '@mui/material';

const LoadingWidget = ({ height, width }) => {
  return (
    <div
      style={{
        minWidth: width ? width : '513px',
        minHeight: height ? height : '389px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingWidget;
